.reasons{
    padding: 0 2rem;
    display: flex;
    /* gap: 2rem; */
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-left: 2rem;
    /* grid-auto-rows: 1fr; */
}
.left-r img{
    object-fit: cover;
}
.left-r :nth-child(1){
    width: 15rem;
    grid-row: 1/3;
    height: 30rem;
}
.left-r :nth-child(2){
    width: auto;
    height: 17.7rem;
    grid-column: 2/4;
}
.left-r :nth-child(3){
    width: 15rem;
    grid-column: 2/3;
    grid-row: 2;
    height: 11.2rem;

}
.left-r :nth-child(4){
    width: 11.5rem;
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;

}
.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-r span{
    font-weight: bold;
    color: var(--orange);
}
.right-r div span{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.b-trans{
    color: transparent !important;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r div{
    display: flex;
    gap: 1rem;
}
.details-r div span{
    font-size: 1rem !important;

}
.details-r div img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
}
.partners img{
    width: 2.5rem;
}
@media (max-width : 768px) {
    .reasons{
        flex-direction: column;
        padding-left: 0;
    }
    .right-r{
        padding-left: 2rem;
    }
    .right-r span:nth-child(1){
        margin-top: 2rem;
    }
    .right-r div :nth-child(1){
        font-size: 2rem;
    }
    .right-r div :nth-child(2){
        font-size: 2rem;
    }
    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-r > :nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r > :nth-child(2){
        width: 15rem;
        height: 10rem;
    }
    .left-r > :nth-child(3){
        width: 8rem;
        height: 6rem;
    }
    .left-r > :nth-child(4){
        width: 7rem;
        height: 5rem;
    }
    
}
